import axios from "@/services/axios";
import { AxiosError, AxiosRequestConfig } from "axios";
import { EmailLoginReq, EmailRegistrationAns, EmailRegistrationReq } from "@/api/types/auth/Email";
import { Tokens } from "@/api/types/auth/Tokens";

export const loginEmail = (fields: EmailLoginReq) => {
	return axios.post<Tokens>('/auth/login/password', fields);
};

export const registerEmail = (fields: EmailRegistrationReq) => {
	return axios.post<EmailRegistrationAns>('/auth/registration/password', fields);
};

export const confirmEmail = (token: string) => {
	return axios.post('/confirm/email', { code: token }, {_noToast: true});
};

export const requestPassChange = (args: {email: string}) => {
	return axios.post('/auth/forgot/email/password', args);
};

export const confirmPassChange = (args: {code: string, newPassword: string}) => {
	return axios.post('/confirm/password', args);
};
