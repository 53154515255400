<script setup lang="ts">



</script>

<template>
	<div class="text-4xl font-comfortaa">
		<p class="inline-block pr-1">
			Bio
		</p>
		<img
			src="@/assets/logo_S.svg"
			class="inline-block h-10"
		>
		<p class="inline-block">
			tudy
		</p>
	</div>
</template>

<style scoped>

</style>
