import { createApp } from "vue";
import { createPinia } from "pinia";
import { AxiosInstance } from "axios";
import { AxiosKey } from "@/symbols/AxiosKey";
import { worker } from "@/mock/browser";
import App from "./App.vue";
import router from "./router";
import axios from "@/services/axios";
import "./styles/app.css";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

// if (process.env.NODE_ENV === 'development') {
// 	worker.start();
// }

const pinia = createPinia();

const options: PluginOptions = { timeout: 5000, position: POSITION.BOTTOM_RIGHT };

const app = createApp(App).use(pinia).use(router).use(Toast, options);

app.provide(AxiosKey, axios);

app.mount("#app");
