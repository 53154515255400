import { isRef, reactive, ref, Ref, shallowRef, toRaw, unref, watch, watchEffect } from "vue";
import axios, { scrapeResData } from "@/services/axios";
import { AxiosError, AxiosRequestConfig } from "axios";
import { User } from "@/api/types/auth/user";

//WARN: Возможно, можно было сделать просто на рефном эндпоинте, но тут задел на дальнейшее расширение

function infixArgCheck(argsArr: [string, any][], endpoint: string) {
	// TODO: Check on the creation of instance
	if(argsArr.length !== endpoint.split("!").length - 1) {
		return false;
	}
	for (const arg of argsArr) {
		if (arg[1] === undefined || arg[1] === null || arg[1] === "") {
			return false;
		}
	}
	return true;
}

function fetcher<DataType>(
	endpoint: string,
	infixArgs?: any,
	postfixArgs?: any,
	config?: AxiosRequestConfig
): Promise<DataType> {
	let params = "";
	// console.log("Infix", infixArgs);
	// console.log("Postfix", postfixArgs);
	if (infixArgs) {
		const argsArr = Object.entries(infixArgs);
		if (infixArgCheck(argsArr, endpoint)) {
			//TODO: заменить ! на :arg
			params = endpoint.split("!").map((part, i, array) => {
				if (i !== array.length - 1)
					return part + argsArr[i]?.[1];
				else
					return part;
			}).join("");
		} else {
			return new Promise((resolve, reject) => {
				reject("Not all infix args are filled " + endpoint);
			});
		}
	} else {
		params += endpoint;
	}

	if (postfixArgs) {
		params += "?" + (new URLSearchParams(postfixArgs)).toString();
	}
	return axios.get<DataType>(params, config).then(scrapeResData<DataType>);
}

function setDefaults(obj: any, defaults: any) {
	if (obj === undefined)
		obj = {};
	for (const key in defaults) {
		if (obj[key] === undefined) {
			obj[key] = defaults[key];
		}
	}
}

type RequestValuesType = {
	endpoint: string,
	args?: object,
}

type RevalOpts = {
	dataUsage?: boolean,
	errorUsage?: boolean,
	saveFallback?: boolean
	//TODO: add retries if nothing happened (MAYBE NOT NEEDED)
	timeout?: number
};

export class swrc<
	DataType,
	InfixArgsType extends object | undefined,
	PostfixArgsType extends object | undefined
> {
	static instanceMap: Map<string, swrc<any, any, any>> = new Map();
	static wipeInstanceMap() {
		swrc.instanceMap = new Map();
		console.warn("Wiped instance map", swrc.instanceMap);
	}
	static getInstance<
		DT, // DataType
		IAT extends object | undefined, // InfixArgsType
		PAT extends object | undefined // PostfixArgsType
	>
	(endpoint: string, unfetchable = false, ia?: IAT, pa?: PAT, config?: AxiosRequestConfig): swrc<DT, IAT, PAT> {
		const key = JSON.stringify(endpoint) +
			(ia? " + IA:" + JSON.stringify(ia):"") +
			(pa? " + PA:" + JSON.stringify(pa):"");
		if (!swrc.instanceMap.has(key)) {
			swrc.instanceMap.set(key, new swrc<DT, IAT, PAT>(endpoint, unfetchable, ia, pa, config));
		}
		const inst = swrc.instanceMap.get(key) as swrc<DT, IAT, PAT>;
		// setInterval(()=>{
		// 	console.log("CHECK INSTANCE", inst);
		// }, 5000);
		return inst;
	}

	static getArgsInstance<
		DT, // DataType
		IAT extends object | undefined, // InfixArgsType
		PAT extends object | undefined, // PostfixArgsType
	>
	(endpoint: string, ia?: Ref<IAT>, pa?: Ref<PAT>, config?: AxiosRequestConfig): Ref<swrc<DT, IAT, PAT>> {
		const swrcRef = shallowRef<swrc<DT, IAT, PAT>>();
		const swapInstance = (reval = false) => {
			// console.log(`Swapping instance of ${endpoint}`, swrc.instanceMap);

			let iaUnref;
			let paUnref;
			let unfetchableFlag = false;

			//INFO: Проблема следующего характера
			// Необходимо проверить, что подаваемые реф объекты имеют все необходимые поля
			// Для этого нужно как-то сравнить те поля, которые подаются в женерике, с теми, что валяются в рефе
			// Пока что можно обеспечить проверку лишь на отсутствие

			if(ia) {
				iaUnref = unref(ia);
				if(iaUnref === undefined || Object.entries(iaUnref).length === 0){
					console.warn("Infix args are undefined", endpoint);
					// console.log(swrc.instanceMap);
					unfetchableFlag = true;
				}
			}

			if(pa) {
				paUnref = unref(pa);
				if(paUnref === undefined || Object.entries(paUnref).length === 0){
					console.warn("Postfix args are undefined", endpoint);
					unfetchableFlag = true;
				}
			}

			const infixArgsUnref = ia?Object.assign({}, iaUnref):undefined;
			const postfixArgsUnref = pa?Object.assign({}, paUnref):undefined;

			const newInst = swrc.getInstance<DT, IAT, PAT>(
				endpoint,
				unfetchableFlag,
				infixArgsUnref,
				postfixArgsUnref,
				config
			);
			// console.log(`New instance of ${endpoint}`, newInst._data.value);
			if ((reval || newInst._data.value == undefined)) {
				if (swrcRef.value == undefined) {
					newInst.revalidate().catch((e) => {
						console.warn("Error while revalidating", e);
					});
					swrcRef.value = newInst;
					return;
				} else {
					newInst.revalidate({ dataUsage: true }).then(() => {
						swrcRef.value = newInst;
					});
					return;
				}
			}
			swrcRef.value = newInst;
		};
		if(ia)
			watch(ia, () => swapInstance(), { deep: true });
		if(pa)
			watch(pa, () => swapInstance(), { deep: true });
		if(!ia && !pa)
			throw new Error("No args provided in getArgsInstance");
		swapInstance(false); //TODO: maybe change to true
		if (swrcRef.value !== undefined)
			return swrcRef as Ref<swrc<DT, IAT, PAT>>;
		else
			throw new Error("swrcRef is undefined somehow...");
	}

	private expirationTime: number;
	private _error: Ref<AxiosError | undefined>; // not generalising with ErrorType because using only axios
	private _data: Ref<DataType | undefined>;
	private _isValidating: Ref<boolean | undefined>;
	private _mutate: (opts?: RevalOpts) => Promise<void | DataType>;

	get error(): Ref<AxiosError | undefined> {
		return this._error;
	}

	get data(): Ref<DataType | undefined> {
		// console.log("Rechecking data");
		if (
			(!this._data.value || Date.now() > this.expirationTime) &&
			!this._isValidating.value &&
			!this._error.value
		) {
			if (Date.now() > this.expirationTime)
				this.expirationTime = Date.now() + this.expireAfter;
			this.revalidate();
		}
		return this._data;
	}

	get isValidating(): Ref<boolean | undefined> {
		return this._isValidating;
	}

	get mutate(): (opts?: RevalOpts) => Promise<void | DataType> {
		return (opts?: RevalOpts) => this._mutate(opts);
	}

	constructor(
		private endpoint: string,
		private unfetchable: boolean = false,
		private infixArgs?: InfixArgsType,
		private postfixArgs?: PostfixArgsType,
		public readonly config?: AxiosRequestConfig,
		private expireAfter = 1000 * 60 * 3 // 3 minutes
	) {
		this._data = ref<DataType>();
		this._error = ref<AxiosError>();
		this._isValidating = ref<boolean>(false);
		this.expirationTime = Date.now() + expireAfter;
		this._mutate = (opts?: RevalOpts) => this.revalidate(opts);
	}

	//TODO: make not only promise
	private revalidate(opts?: RevalOpts): Promise<void | DataType> {
		if (this.unfetchable) {
			this._isValidating.value = false;
			return new Promise((resolve, reject) => {
				reject("Not revalidating unfetchable swrc");
			});
		}
		setDefaults(opts, {
			dataUsage: false,
			errorUsage: false,
			saveFallback: false,
			timeout: 0
		});
		this._isValidating.value = true;
		return new Promise((resolve, reject) => {
			if (!opts?.dataUsage)
				resolve();
			setTimeout(()=>{
				fetcher<DataType>(this.endpoint, this.infixArgs, this.postfixArgs, this.config)
					.then((data) => {
						this._data.value = data;
						this._error.value = undefined;
						this._isValidating.value = false;
						resolve(data);
					})
					.catch((error) => {
						console.warn("Error while fetching", error);
						this._error.value = error;
						this._isValidating.value = false;
						if (opts?.saveFallback) {
							this._data.value = undefined;
						}
						if (opts?.errorUsage)
							reject(error);
						resolve();
					});
			}, opts?.timeout);
		});
	}
}
