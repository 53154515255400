import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import HomeLayout from "@/layouts/HomeLayout.vue";
import CoursesLayout from "@/layouts/CoursesLayout.vue";
import CatalogueLayout from "@/layouts/CatalogueLayout.vue";
import { useAuthStore } from "@/stores/auth";
import { swrc } from "@/services/swry";
import LearnLayout from "@/layouts/LearnLayout.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "home",
		component: HomeView,
		meta: {
			layout: HomeLayout,
			requiresAuth: false
		}
	},
	{
		path: "/userAgreement",
		name: "userAgreement",
		component: () => import(/* webpackChunkName: "userAgreement" */ "../views/UserAgreementView.vue"),
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "/help",
		name: "help",
		component: () => import(/* webpackChunkName: "help" */ "../views/HelpView.vue"),
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "/about",
		name: "about",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "/debug",
		name: "debug",
		component: () =>
			import(/* webpackChunkName: "debug" */ "../views/DebugView.vue"),
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "/debug2",
		name: "debug2",
		component: () =>
			import(/* webpackChunkName: "debug" */ "../views/Debug2View.vue"),
		meta: {
			requiresAuth: false
		}
	},
	{
		path: '/confirm-email',
		name: 'verification',
		component: () =>
			import(/*  webpackChunkName: "debug" */ "../views/VerificationView.vue"),
		// props: (route) => ({ token: route.query.token }),
		meta: {
			requiresAuth: false
		},
	},
	{
		path: '/restore-password',
		name: 'passRecovery',
		component: () => import(/* webpackChunkName: "passRecovery" */ "../views/PassRecoveryView.vue"),
		meta: {
			requiresAuth: false
		}
	},
	{
		path: "/catalogue",
		name: "coursesCatalogue",
		component: () =>
			import(/* webpackChunkName: "catalogue" */ "../views/CatalogueView.vue"),
		meta: {
			layout: CatalogueLayout,
			requiresAuth: false
		},
		children: [
		]
	},
	//TODO: почему-то не получается засунуть в children
	{
		path:'/catalogue/:id',
		name: 'catalogue_course',
		meta: {
			layout: CatalogueLayout,
			requiresAuth: false
		},
		component: ()=>
			import(/* webpackChunkName: "catalogue_course" */ "../views/courses/CatalogueCourseView.vue"),
	},
	{
		path: "/cruds",
		name: "cruds",
		component: () =>
			import(/* webpackChunkName: "cruds" */ "../views/CrudsView.vue"),
		meta: {
			layout: CatalogueLayout,
			requiresAuth: true
		}
	},
	{
		path: "/courses",
		name: "courses",
		redirect: "/courses/owned",
		component: ()=>
			import(/* webpackChunkName: "courses_layout" */ "../layouts/CoursesLayout.vue"),
		children: [
			{
				path:'owned',
				component: ()=>
					import(/* webpackChunkName: "courses_owned" */ "../views/courses/OwnedView.vue")
			},
			{
				path:'active',
				component: ()=>
					import(/* webpackChunkName: "courses_active" */ "../views/courses/ActiveView.vue")
			},
			{
				path:'archive',
				component: ()=>
					import(/* webpackChunkName: "courses_archive" */ "../views/courses/ArchiveView.vue")
			},
			{
				path:'desired',
				component: ()=>
					import(/* webpackChunkName: "courses_desired" */ "../views/courses/DesiredView.vue")
			},
		]
	},
	{
		path: "/learn/:courseId",
		name: "learn",
		component: () =>
			import(/* webpackChunkName: "learn" */ "../layouts/LearnLayout.vue"),
		children: [
			{
				path: 'step/:stepId',
				name: 'learn_step',
				component: () =>
					import(/* webpackChunkName: "learn_step" */ "../views/LearnView.vue"),
			}
		]
	},
	//system admins / organisations / orgadmins / courses / courseadmins / aourseacc
	{
		path: '/admin',
		name: 'admin',
		component: () =>
			import(/* webpackChunkName: "admin" */ "../layouts/AdminLayout.vue"),
		children: [
			{
				path: 'system-admins',
				component: () =>
					import(/* webpackChunkName: "admin_system_admins" */ "../views/admin/SystemAdminsView.vue")
			},
			{
				path: 'organisations',
				name: 'admin_organisations',
				component: () =>
					import(/* webpackChunkName: "admin_organisations" */ "../views/admin/OrganisationsView.vue"),
				children: [
					{
						path: ':id',
						name: 'admin_organisation_settings',
						component: () =>
							import(/* webpackChunkName: "admin_organisation_settings" */
								"../views/admin/OrganisationSettingsView.vue")
					}
				]
			},
			{
				path: 'courses',
				name: 'admin_courses',
				component: () =>
					import(/* webpackChunkName: "admin_courses" */ "../views/admin/CoursesView.vue"),
				children: [
					{
						path: ':orgId/:courseId',
						name: 'admin_course_settings',
						component: () =>
							import(/* webpackChunkName: "admin_course_settings" */
								"../views/admin/CourseEditView.vue"),
					},
					{
						path: ':orgId/:courseId/:stepId',
						name: 'admin_course_step_settings',
						component: () =>
							import(/* webpackChunkName: "admin_course_step_settings" */
								"../views/admin/CourseStepEditView.vue"),
					},
					{
						path: 'new/:organisationId',
						name: 'admin_course_new_oid',
						component: () =>
							import(/* webpackChunkName: "admin_course_new" */
								"../views/admin/CourseCreateView.vue")
					}
				]
			},
			{
				path: 'groups',
				name: 'admin_groups',
				component: () =>
					import(/* webpackChunkName: "admin_groups" */ "../views/admin/GroupsView.vue"),
				children: [
					{
						path: ':courseId/list',
						name: 'admin_group_list',
						component: () =>
							import(/* webpackChunkName: "admin_group_list" */
								"../views/admin/GroupsListView.vue")
					}
				]
			}
		]
	},
	{
		path: '/:pathMatch(.*)*',
		component: () =>
			import(/* webpackChunkName: "not_found" */ "../views/Errors/404View.vue"),
		meta: {
			requiresAuth: false
		}
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from) => {
	// ✅ This will work because the router starts its navigation after
	// the router is installed and pinia will be installed too
	const authStore = useAuthStore();

	if ( // проверяем, что флаг отсутствует, либо он true, тогда откидываем на логин
		(
			!(
				to.meta.requiresAuth!==undefined &&
				to.meta.requiresAuth===false
			) &&
			(
				to.meta.requiresAuth!==undefined &&
				to.meta.requiresAuth===true
			)
		) || (to.meta.requiresAuth===undefined)
		&&
		!authStore.isLoggedIn
	) {
		try {
			//TODO: watch on error and switch isLoggedIn to false
			await swrc.getInstance('/self').mutate({dataUsage: true, errorUsage: true});
			authStore.isLoggedIn = true;
		} catch (err) {
			console.warn(`Restricted`, to);
			authStore.showLoginDialog = true;
			authStore.isLoggedIn = false;
			return '/';
		}
	}
});



export default router;
