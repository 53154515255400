// const cookies = useCookies(['token', 'refresh']);
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";
import { Tokens } from "@/api/types/auth/Tokens";
import { useAuthStore } from "@/stores/auth";
import { POSITION, useToast } from "vue-toastification";

const refreshKey = "refresh";
const tokenKey = "token";

const token = localStorage.getItem(tokenKey);
let url = "";
const isDevServer = process.env.VUE_APP_IS_DEV_SERVER;
if (isDevServer === '1')
	url = "https://api.test.bio-study.ru/api/v1";
else if(isDevServer === '0')
	url = "https://api.bio-study.ru/api/v1";
else if(isDevServer === '2')
	url = "http://192.168.0.101:8080/api/v1";

// Creating new instance
const instance = axios.create({
	// baseURL: "",
	// baseURL: "https://api.test.bio-study.ru/api/v1",
	// baseURL: "http://192.168.0.101:8080/api/v1",
	baseURL: url,
	headers: {
		Authorization: token ?
			`Bearer ${token}` :
			""
	}
});


export async function refreshAccessToken(): Promise<Tokens>{
	const refresh = localStorage.getItem(refreshKey);
	if (!refresh) {
		await router.push("/");
		throw new Error("Lost refresh token");
	}
	return instance.post("/auth/refresh", {
		refresh: refresh
	})
		.then(response => {
			return response.data.token as Tokens;
		})
		.catch(async err => {
			await router.push("/");
			throw new Error("Couldn't refresh token");
		});
}

export function setTokens(instance: AxiosInstance, tokens: Tokens) {
	useAuthStore().isLoggedIn = true;
	localStorage.setItem(tokenKey, tokens.token);
	instance.defaults.headers["Authorization"] = `Bearer ${tokens.token}`;
	localStorage.setItem(refreshKey, tokens.refresh);
}

export function unsetTokens(instance: AxiosInstance) {
	useAuthStore().isLoggedIn = false;
	localStorage.removeItem(tokenKey);
	instance.defaults.headers["Authorization"] = "";
	localStorage.removeItem(refreshKey);
}

export const scrapeErrData = <T>(err: AxiosError) => {
	return err?.response?.data as T;
};

export const scrapeResData = <T>(res: AxiosResponse<T>) => {
	return res.data as T;
};

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const originalRequest = error.config as AxiosRequestConfig;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			const authStore = useAuthStore();
			return refreshAccessToken()
				.then((newAccessTokens) => {
					setTokens(instance, newAccessTokens);
					if(!originalRequest.headers)
						originalRequest.headers = {};
					originalRequest.headers.Authorization = `Bearer ${newAccessTokens.token}`;
					authStore.isLoggedIn = true;
					return axios(originalRequest);
				})
				.catch(err =>{
					console.warn(err);
					authStore.isLoggedIn = false;
					unsetTokens(instance);
					return Promise.reject(error);
				});
		}
		else {
			const toast = useToast();
			let msg = scrapeErrData<any>(error)?.message as string;
			console.log("Error message:", msg);
			if(!originalRequest._noToast){
				if(!msg || !msg.length)
					msg = originalRequest._toastUnkErrMsg ?? "Неизвестная ошибка";
				toast.error(msg);
			}
			return Promise.reject(error);
		}
	}
);

export default instance;
