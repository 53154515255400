import { defineStore } from "pinia";

type State = {
	isLoggedIn: boolean,
	showLoginDialog: boolean,
	showPassRecoveryDialog: boolean,
}

export const useAuthStore = defineStore("auth", {
	state: (): State => ({
		isLoggedIn: false,
		showLoginDialog: false,
		showPassRecoveryDialog: false,
	}),
	actions: {

	}
});
